import React from 'react'
import Layout from '../../../components/Default/Layout'
import '../../../components/ParagraphUnderPhoto/styles.css'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import './styles.css'
import Carousel from 'react-multi-carousel'
import { Link } from '@reach/router'
import { ResponsiveImage } from '../../../components/ResponsiveImage'

const NasTym = ({ data: { page }, pageContext }) => {
  const {
    breadcrumb: { crumbs }
  } = pageContext

  const URLbase = '/o-nas/nas-tym/'

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    desktopTablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  const { image, title } = page.frontmatter
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      metaCrumb={crumbs}
    >
      <ServiceHeader
        title={title}
        backgroundImage={
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        }
      />
      <Breadcrumb crumbs={crumbs} crumbLabel={'Náš tým'} />

      <div className="Paragraph--Wrapper container NasTym">
        <p>
          Součástí týmu jsou odborníci na finanční či mzdové účetnictví, přímé i
          nepřímé daně, firemní poradenství či soudní znalci v oblasti
          účetnictví a daní. Důležité pro nás je, aby se každý člen ve své
          specializaci neustále rozvíjel a sledoval veškeré legislativní změny.
        </p>
        <p>
          Spolupracovat budete výhradně s certifikovanými daňovými poradci a
          kvalifikovanými účetními tak, aby veškeré výstupy byly precizní, včas
          a mohli jste se na nás spolehnout. Celý tým zároveň funguje efektivně
          jako celek, s ohledem nejen na profesní specializaci, ale také s
          respektem k osobnostním profilům členů a důrazem na rozvoj soft
          skills. Díky tomu můžete očekávat nejen perfektní daňové a účetní
          výstupy, ale také profesionální jednání a snadnou komunikaci.
        </p>
        <p>
          Hlavní osu týmu pak tvoří zakládající partneři společnosti a poradci
          zapsaní v Komoře daňových poradců – Jan Kotala a Lukáš Eisenwort. Oba
          vycházejí z bohaté praxe v poradenských společnostech, neustále se
          vzdělávají, sami přednášejí a od roku 2015 poskytují služby pod
          hlavičkou vlastní firmy, EKP Advisory, s.r.o.
        </p>
      </div>
      <br />
      <br />
      <div className="container" style={{ textAlign: 'center' }}>
        <div className="row align-items-center">
          <div className="col-lg-12 blue-line-three"></div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <Link to={URLbase + 'lukas-eisenwort/'}>
              <ResponsiveImage
                className="img-transform-scale"
                src="/images/team/lukas_eisenwort.jpg"
                alt="Lukáš Eisenwort"
                width="252"
              />
              <p className="NasTym--Name">Lukáš Eisenwort</p>
              <p className="NasTym--Position">partner, daňový poradce</p>
            </Link>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <Link to={`${URLbase}jan-kotala/`}>
              <ResponsiveImage
                className="img-transform-scale"
                src="/images/team/jan_kotala.jpg"
                alt="Jan Kotala"
                width="252"
              />
              <p className="NasTym--Name">Jan Kotala</p>
              <p className="NasTym--Position">partner, daňový poradce</p>
            </Link>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <Link to={`${URLbase}jan-tecl/`}>
              <ResponsiveImage
                className="img-transform-scale"
                src="/images/team/jan_tecl.jpg"
                alt="Jan Tecl"
                width="252"
              />
              <p className="NasTym--Name">Jan Tecl</p>
              <p className="NasTym--Position">partner, daňový poradce</p>
            </Link>
          </div>
        </div>
        {/*<--------- pozice manažeři ---------> */}
        <h4 className="NasTym--H4">Manažeři</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <Link to={URLbase + 'jana-peterkova/'}>
            <ResponsiveImage
              className="NasTym--Image"
              src="/images/team/jana_peterkova.jpg"
              alt="Jana Peterková"
            />
            <p className="NasTym--Name">Jana Peterková</p>
            <p className="NasTym--Position">manažer, daňový poradce</p>
          </Link>
          <Link to={URLbase + 'michal-skrabis/'}>
            <ResponsiveImage
              className="NasTym--Image"
              src="/images/team/michal_skrabis.jpg"
              alt="Michal Škrabiš"
            />
            <p className="NasTym--Name">Michal Škrabiš</p>
            <p className="NasTym--Position">manažer, daňový poradce</p>
          </Link>
          <Link to={`${URLbase}jakub-beran/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/jakub_beran.jpg"
                alt="Jakub Beran"
              />
              <p className="NasTym--Name">Jakub Beran</p>
              <p className="NasTym--Position">manažer, daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}miroslav-skopec/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/miroslav_skopec.jpg"
                alt="Miroslav Skopec"
              />
              <p className="NasTym--Name">Miroslav Skopec</p>
              <p className="NasTym--Position">manažer, daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}ladislav-zach/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/ladislav_zach.jpg"
                alt="Ladislav Zach"
              />
              <p className="NasTym--Name">Ladislav Zach</p>
              <p className="NasTym--Position">manažer, daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}zuzana-tregnerova/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/zuzana_tregnerova.jpg"
                alt="Zuzana Tregnerová"
              />
              <p className="NasTym--Name">Zuzana Tregnerová</p>
              <p className="NasTym--Position">manažer, daňový poradce</p>
            </div>
          </Link>
        </Carousel>

        {/*<--------- / pozice manažeři ---------> */}
        {/*<--------- pozice daňoví poradci ---------> */}
        <h4 className="NasTym--H4">Daňoví poradci</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={8000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <Link to={`${URLbase}petr-kolar/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/petr_kolar.jpg"
                alt="Petr Kolář"
              />
              <p className="NasTym--Name">Petr Kolář</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/gabriela_kopecka.jpg"
              alt="Gabriela Kopecká"
            />
            <p className="NasTym--Name">Gabriela Kopecká</p>
            <p className="NasTym--Position">daňový poradce</p>
          </div>
          <Link to={`${URLbase}dominika-siplova/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/dominika_siplova.jpg"
                alt="Dominika Šiplová"
              />
              <p className="NasTym--Name">Dominika Šiplová</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>

          <Link to={`${URLbase}lucie-jeriova/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/lucie_jeriova.jpg"
                alt="Lucie Jeriová"
              />
              <p className="NasTym--Name">Lucie Jeriová</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}anh-duc-nguyen/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/anh_nguyen.jpg"
                alt="Anh Duc Nguyen"
              />
              <p className="NasTym--Name">Anh Duc Nguyen</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}tomas-vrana/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/tomas_vrana.jpg"
                alt="Tomáš Vrána"
              />
              <p className="NasTym--Name">Tomáš Vrána</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}tereza-cihova/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/team_icon.jpg"
                alt="Tereza Číhová"
              />
              <p className="NasTym--Name">Tereza Číhová</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}jiri-broz/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/jiri_broz.jpg"
                alt="Jiří Brož"
              />
              <p className="NasTym--Name">Jiří Brož</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
          <Link to={`${URLbase}vojtech-bouril/`}>
            <div className="NasTym--Card">
              <img
                className="NasTym--Image"
                src="/images/team/vojtech_bouril.jpg"
                alt="Vojtěch Bouřil"
              />
              <p className="NasTym--Name">Vojtěch Bouřil</p>
              <p className="NasTym--Position">daňový poradce</p>
            </div>
          </Link>
        </Carousel>
        {/*<--------- / pozice daňoví poradci ---------> */}
        {/*<--------- pozice asistenti daňových poradců ---------> */}
        <h4 className="NasTym--H4">Asistenti daňových poradců</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/julie_babjakova.jpg"
              alt="Julie Babjáková"
            />
            <p className="NasTym--Name">Julie Babjáková</p>
            <p className="NasTym--Position">asistentka daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/ludmila_cerna.jpg"
              alt="Ludmila Černá"
            />
            <p className="NasTym--Name">Ludmila Černá</p>
            <p className="NasTym--Position">asistentka daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/lukas_jankovec.jpg"
              alt="Lukáš Jankovec"
            />
            <p className="NasTym--Name">Lukáš Jankovec</p>
            <p className="NasTym--Position">asistent daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/lukas_neradilek.jpg"
              alt="Lukáš Neradílek"
            />
            <p className="NasTym--Name">Lukáš Neradílek</p>
            <p className="NasTym--Position">asistent daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/martin_ziba.jpg"
              alt="Martin Ziba"
            />
            <p className="NasTym--Name">Martin Ziba</p>
            <p className="NasTym--Position">asistent daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/martin_strnad.jpg"
              alt="Martin Strnad"
            />
            <p className="NasTym--Name">Martin Strnad</p>
            <p className="NasTym--Position">asistent daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/kamila_adamova.jpg"
              alt="Kamila Adamová"
            />
            <p className="NasTym--Name">Kamila Adamová</p>
            <p className="NasTym--Position">asistentka daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/eliska_krutska.jpg"
              alt="Eliška Krutská"
            />
            <p className="NasTym--Name">Eliška Krutská</p>
            <p className="NasTym--Position">asistentka daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/silvie_dolezalova.jpg"
              alt="Silvie Doležalová"
            />
            <p className="NasTym--Name">Silvie Doležalová</p>
            <p className="NasTym--Position">asistentka daňového poradce</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Filip Teplý"
            />
            <p className="NasTym--Name">Filip Teplý</p>
            <p className="NasTym--Position">asistent daňového poradce</p>
          </div>
        </Carousel>
        {/*<--------- / pozice asistenti daňových poradců ---------> */}
        {/*<--------- pozice účetních ---------> */}
        <h4 className="NasTym--H4">Finanční a mzdové účetní</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/sandra_hladikova.jpg"
              alt="Sandra Hladíková"
            />
            <p className="NasTym--Name">Sandra Hladíková</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/monika_oberreiterova.jpg"
              alt="Monika Oberreiterová"
            />
            <p className="NasTym--Name">Monika Oberreiterová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/eliska_pavlickova.jpg"
              alt="Eliška Pavlíčková"
            />
            <p className="NasTym--Name">Eliška Pavlíčková</p>
            <p className="NasTym--Position">finanční a mzdová účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jana_polanska.jpg"
              alt="Jana Polanská"
            />
            <p className="NasTym--Name">Jana Polanská</p>
            <p className="NasTym--Position">finanční a mzdová účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/linh_tran.jpg"
              alt="Tran Thi Thuy Linh"
            />
            <p className="NasTym--Name">Tran Thi Thuy Linh</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/simona_knezickova.jpg"
              alt="Simona Kněžíčková"
            />
            <p className="NasTym--Name">Simona Kněžíčková</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/kristyna_blazkova.jpg"
              alt="Kristýna Blažková"
            />
            <p className="NasTym--Name">Kristýna Blažková</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/simona_zimmermannova.jpg"
              alt="Simona Zimmermannová"
            />
            <p className="NasTym--Name">Simona Zimmermannová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/denisa_krizova.jpg"
              alt="Denisa Křížová"
            />
            <p className="NasTym--Name">Denisa Křížová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/aneta_spackova.jpg"
              alt="Aneta Špačková"
            />
            <p className="NasTym--Name">Aneta Špačková</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/dagmar_stranakova.jpg"
              alt="Dagmar Straňáková"
            />
            <p className="NasTym--Name">Dagmar Straňáková</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jana_gabrielova.jpg"
              alt="Jana Gabrielová"
            />
            <p className="NasTym--Name">Jana Gabrielová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/katerina_munduchova.jpg"
              alt="Kateřina Munduchová"
            />
            <p className="NasTym--Name">Kateřina Munduchová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/adela_zimmermannova.jpg"
              alt="Adéla Zimmermannová"
            />
            <p className="NasTym--Name">Adéla Zimmermannová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Michaela Pakostová"
            />
            <p className="NasTym--Name">Michaela Pakostová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Karolína Fojtíková"
            />
            <p className="NasTym--Name">Karolína Fojtíková</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Lenka Soukupová"
            />
            <p className="NasTym--Name">Lenka Soukupová</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Eva Pauličič"
            />
            <p className="NasTym--Name">Eva Pauličič</p>
            <p className="NasTym--Position">finanční účetní</p>
          </div>
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Dana Konečná"
            />
            <p className="NasTym--Name">Dana Konečná</p>
            <p className="NasTym--Position">mzdová účetní</p>
          </div>
        </Carousel>
        {/*<--------- / pozice účetních ---------> */}

        {/* <h4 className="NasTym--H4">Back office</h4>
        <div className="underline-yellow" />
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={5000}
          centerMode={false}
          className="NasTym--Carousel--Wrap"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/martin_fryc.jpg"
              alt="Martin Fryč"
            />
            <p className="NasTym--Name">Martin Fryč</p>
            <p className="NasTym--Position">asistent jednatele</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/jaroslava_fialova.jpg"
              alt="Jaroslava Fialová"
            />
            <p className="NasTym--Name">Jaroslava Fialová</p>
            <p className="NasTym--Position">asistentka jednatele</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/petra_vopatova.jpg"
              alt="Petra Vopatová"
            />
            <p className="NasTym--Name">Petra Vopatová</p>
            <p className="NasTym--Position">vedoucí recepce</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/tetana_symkovycova.jpg"
              alt="Teťana Symkovyčová"
            />
            <p className="NasTym--Name">Teťana Symkovyčová</p>
            <p className="NasTym--Position">asistentka recepce</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/team_icon.jpg"
              alt="Martina Zelenková"
            />
            <p className="NasTym--Name">Martina Zelenková</p>
            <p className="NasTym--Position">asistentka recepce</p>
          </div>

          <div className="NasTym--Card">
            <img
              className="NasTym--Image"
              src="/images/team/ondrej_starha.jpg"
              alt="Ondřej Štarha"
            />
            <p className="NasTym--Name">Ondřej Štarha</p>
            <p className="NasTym--Position">IT specialista</p>
          </div>
        </Carousel> */}
      </div>
      {/* společná fotka */}
      {/* {!!image1 && !!image1.childImageSharp ? (
        <Img
          fluid={image1.childImageSharp.fluid}
          style={{ marginBottom: '-128px', marginTop: '128px' }}
          alt={title}
        />
      ) : (
        <img src={image1.src} alt="" />
      )} */}
    </Layout>
  )
}

export default NasTym

export const pageQuery = graphql`
  query NasTym {
    page: markdownRemark(frontmatter: { slug: { eq: "o-nas/nas-tym" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    image1: file(relativePath: { eq: "nas-tym.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
